import React from 'react'
import services1 from '../assets/images/home-4/service1.png'
import services2 from '../assets/images/home-4/service2.png'
import services3 from '../assets/images/home-4/service3.png'
import services4 from '../assets/images/home-4/service4.png'
import services5 from '../assets/images/home-4/service5.png'
import services6 from '../assets/images/home-4/service6.png'
import services7 from '../assets/images/home-4/service7.png'
import services8 from '../assets/images/home-4/service8.png'
import services9 from '../assets/images/home-4/service9.png'
import services10 from '../assets/images/home-4/service10.png'
import about1 from '../assets/images/home-4/about1.png'
import Worker1 from '../assets/images/home-4/work1.png'
import Worker2 from '../assets/images/home-4/work2.png'
import Worker3 from '../assets/images/home-4/work3.png'
import Worker4 from '../assets/images/home-4/work4.png'

import works1 from '../assets/images/home-4/work1.png'



import team1 from '../assets/images/home-4/team1.png'
import team2 from '../assets/images/home-4/team2.png'
import team3 from '../assets/images/home-4/team3.png'
import team4 from '../assets/images/home-4/team4.png'

import testi1 from '../assets/images/home-4/testi1.png'
import testi2 from '../assets/images/home-4/testi2.png'
import testi3 from '../assets/images/home-4/testi3.png'

const Uiux = () => {
  return (
    <div>
      <div className="service-area style-foure">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title style-foure text-left">
                <h5 className="section-sub-title">SERVICE FEATURES</h5>
                <h1 className="section-main-title">Let’s Discover Our Service</h1>
                <h1 className="section-main-title">Features Charter.</h1>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-single-box">
                <div className="service-icon">
                  <img src={services1} alt='services 1' />                  
                </div>
                <div className="service-content">
                  <h3 className="service-title">Strategic marketing</h3>
                  <p className="service-desc">Profesionaly optimize developer team infomediaries main issue don.</p>
                </div>
                <div className="service-icon-two">
                  <a href="#"><i className="bi bi-chevron-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-single-box">
                <div className="service-icon">
                <img src={services2} alt='services 2' />  
                </div>
                <div className="service-content">
                  <h3 className="service-title">Investment Planning</h3>
                  <p className="service-desc">Profesionaly optimize developer team infomediaries main issue don.</p>
                </div>
                <div className="service-icon-two">
                  <a href="#"><i className="bi bi-chevron-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-single-box">
                <div className="service-icon">
                <img src={services3} alt='services 3' />  
                </div>
                <div className="service-content">
                  <h3 className="service-title">Insights &amp; analytics</h3>
                  <p className="service-desc">Profesionaly optimize developer team infomediaries main issue don.</p>
                </div>
                <div className="service-icon-two">
                  <a href="#"><i className="bi bi-chevron-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-single-box">
                <div className="service-icon">
                <img src={services4} alt='services 4' />                 
                </div>
                <div className="service-content">
                  <h3 className="service-title">Business consulting</h3>
                  <p className="service-desc">Profesionaly optimize developer team infomediaries main issue don.</p>
                </div>
                <div className="service-icon-two">
                  <a href="#"><i className="bi bi-chevron-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-single-box">
                <div className="service-icon">
                <img src={services5} alt='services 5' />  
                </div>
                <div className="service-content">
                  <h3 className="service-title">Finance and Restructuring</h3>
                  <p className="service-desc">Profesionaly optimize developer team infomediaries main issue don.</p>
                </div>
                <div className="service-icon-two">
                  <a href="#"><i className="bi bi-chevron-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-single-box">
                <div className="service-icon">
                <img src={services6} alt='services 6' />  
                </div>
                <div className="service-content">
                  <h3 className="service-title">Audit and Evaluation</h3>
                  <p className="service-desc">Profesionaly optimize developer team infomediaries main issue don.</p>
                </div>
                <div className="service-icon-two">
                  <a href="#"><i className="bi bi-chevron-right" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech service Area Style foure*/}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start Toptech about Area Style foure*/}
      {/*==================================================*/}
      <div className="about-area style-foure">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title style-foure text-left">
                <h5 className="section-sub-title">ABOUT COMPANY</h5>
                <h1 className="section-main-title">Expert Consulting for Diverse</h1>
                <h1 className="section-main-title">Financial Services.</h1>
                <p className="description">Professionally optimize interdependent intellectual interoperable
                  visuali design infoediaries main issue state icreative planing main
                  best practices.</p>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="about-title">
                    <h3>Business Process Outsourcing.</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="about-title style-two">
                    <h3>Business Process Outsourcing.</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="about-title">
                    <h3>Technology Consulting.</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="about-title style-two">
                    <h3>Technology Consulting.</h3>
                  </div>
                </div>
              </div>
              <div className="dreamit-button">
                <a href="#">GET STARTED NOW</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-thumb">
                <img src={about1} alt="about1" />
                <div className="counter-box">
                  <div className="counter-number">
                    <h1 className="counter">20</h1>
                  </div>
                  <div className="counter-title">
                    <h3>Years<br />off Exprience</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="counter-area style-foure">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="counter-single-box-tow">
                <div className="counter-number-tow">
                  <h1 className="counter">40</h1>
                  <h1>+</h1>
                </div>
                <div className="counter-content">
                  <h4>Years Experience</h4>
                  <p>Profesionaly optimize Design team
                    feature main issue don.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="counter-single-box-tow">
                <div className="counter-number-tow">
                  <h1 className="counter">60</h1>
                  <h1>+</h1>
                </div>
                <div className="counter-content">
                  <h4>Client Satisfaction</h4>
                  <p>Profesionaly optimize Design team
                    feature main issue don.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="counter-single-box-tow">
                <div className="counter-number-tow">
                  <h1 className="counter">50</h1>
                  <h1>+</h1>
                </div>
                <div className="counter-content">
                  <h4>Awards Wining</h4>
                  <p>Profesionaly optimize Design team
                    feature main issue don.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row service">
            <div className="col-lg-6">
              <div className="section-title style-foure text-left">
                <h5 className="section-sub-title">out toptech services</h5>
                <h1 className="section-main-title">Let’s Discover Our Service</h1>
                <h1 className="section-main-title">Features Charter.</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dreamit-button">
                <a href="#">Make an Appointment </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-single-box-tow">
                <div className="service-icon-two">
                  <img src={services7} alt="service 7" /> 
                </div>
                <div className="sevice-content-tow">
                  <h3 className="service-title-tow">Business Planning</h3>
                  <p className="service-desc-two">Morem area are psum dolor com
                    sitteme odern sectet aweur chat
                    adipiscing always.</p>
                  <div className="service-btn-tow">
                    <a href="#">READ MORE</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-single-box-tow">
                <div className="service-icon-two">
                <img src={services8} alt="service 8" />                  
                </div>
                <div className="sevice-content-tow">
                  <h3 className="service-title-tow">Problem-Solving</h3>
                  <p className="service-desc-two">Morem area are psum dolor com
                    sitteme odern sectet aweur chat
                    adipiscing always.</p>
                  <div className="service-btn-tow">
                    <a href="#">READ MORE</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-single-box-tow">
                <div className="service-icon-two">
                 <img src={services9} alt="service 9" /> 
                </div>
                <div className="sevice-content-tow">
                  <h3 className="service-title-tow">Project Reporting</h3>
                  <p className="service-desc-two">Morem area are psum dolor com
                    sitteme odern sectet aweur chat
                    adipiscing always.</p>
                  <div className="service-btn-tow">
                    <a href="#">READ MORE</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-single-box-tow">
                <div className="service-icon-two">
                <img src={services10} alt="service 10" />                   
                </div>
                <div className="sevice-content-tow">
                  <h3 className="service-title-tow">Professional Team</h3>
                  <p className="service-desc-two">Morem area are psum dolor com
                    sitteme odern sectet aweur chat
                    adipiscing always.</p>
                  <div className="service-btn-tow">
                    <a href="#">READ MORE</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech counter Area Style foure*/}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start Toptech work Area Style foure*/}
      {/*==================================================*/}
      <div className="work-area style-foure">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title style-foure text-center">
                <h5 className="section-sub-title">OUR WORK SHOWCASE</h5>
                <h1 className="section-main-title">Where Innovation Meets Success</h1>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="work-single-box">
                <div className="work-thumb">
                 <img src={Worker1} alt="work 1" />                  
                  <div className="work-icon">
                    <a href="#"><i className="bi bi-arrow-right" /></a>
                  </div>
                  <div className="work-content">
                    <a href="#"><h1 className="work-title">Market Analysis</h1></a>
                    <p className="worK-text">Technology</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="work-single-box">
                <div className="work-thumb">
                  <img src={Worker2} alt="work 2" />                  
                  <div className="work-icon">
                    <a href="#"><i className="bi bi-arrow-right" /></a>
                  </div>
                  <div className="work-content">
                    <a href="#"><h1 className="work-title">Risk Assessment</h1></a>
                    <p className="worK-text">Technology</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="work-single-box">
                <div className="work-thumb">
                  <img src={Worker3} alt="work 3" />                   
                  <div className="work-icon">
                    <a href="#"><i className="bi bi-arrow-right" /></a>
                  </div>
                  <div className="work-content">
                    <a href="#"><h1 className="work-title">Investment Insights</h1></a>
                    <p className="worK-text">Technology</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="work-single-box">
                <div className="work-thumb">
                  <img src={Worker4} alt="work 4" />                  
                  <div className="work-icon">
                    <a href="#"><i className="bi bi-arrow-right" /></a>
                  </div>
                  <div className="work-content">
                    <a href="#"><h1 className="work-title">Estate Planning</h1></a>
                    <p className="worK-text">Technology</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech Faqs Area Style One*/}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start Toptech Works Area Style Foure*/}
      {/*==================================================*/}
      <div className="works-area style-foure">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="works-thumb">                
                <img src={works1} alt="works1" />
                <div className="works-video-icon">
                  <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://www.youtube.com/watch?v=Wx48y_fOfiY">Play</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title style-foure text-left">
                <h5 className="section-sub-title">Work processing</h5>
                <h1 className="section-main-title">Expert Consulting for Diverse</h1>
                <h1 className="section-main-title">Financial Services.</h1>
                <p className="description">Professionaly optimize interdependent intelectual services
                  visuali design infoediaries main issue state icreative planing main
                  best practices.</p>
                <p className="works">Our team of seasoned experts is here to you to unlock your provid
                  business’s potential and achieve remar success.</p>
              </div>
              <div className="dreamit-button">
                <a href="#">READ MORE</a>
              </div>
              <div className="dreamit-button2">
                <a href="#">CUSTOMER DESTAILS <i className="bi bi-arrow-right" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech Works Area Style Foure*/}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start Toptech Project Area Style Foure*/}
      {/*==================================================*/}
      <div className="project-area style-foure">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="project-single-box">
                <div className="project-content">
                  <h1 className="project-number">01</h1>
                  <h3 className="project-title">Project Processing</h3>
                  <p className="project-desc">Process optimize developer in provid
                    creative main issue finance.</p>
                  <div className="project-btn">
                    <a href="#">READ MORE <i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="project-single-box">
                <div className="project-content">
                  <h1 className="project-number">02</h1>
                  <h3 className="project-title">High Quality Products</h3>
                  <p className="project-desc">Process optimize developer in provid
                    creative main issue finance.</p>
                  <div className="project-btn">
                    <a href="#">READ MORE <i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="project-single-box">
                <div className="project-content">
                  <h1 className="project-number">03</h1>
                  <h3 className="project-title">Quality Finished</h3>
                  <p className="project-desc">Process optimize developer in provid
                    creative main issue finance.</p>
                  <div className="project-btn">
                    <a href="#">READ MORE <i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="project-single-box">
                <div className="project-content">
                  <h1 className="project-number">04</h1>
                  <h3 className="project-title">Huge Choice Products</h3>
                  <p className="project-desc">Process optimize developer in provid
                    creative main issue finance.</p>
                  <div className="project-btn">
                    <a href="#">READ MORE <i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech Project Area Style Foure*/}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start Toptech Team Area Style Foure*/}
      {/*==================================================*/}
      <div className="team-area style-foure">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title style-foure text-center">
                <h5 className="section-sub-title">TEAM MEMBER</h5>
                <h1 className="section-main-title">Meet Our Leadership Team</h1>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-box">
                <div className="team-thumb">
                  <img src={team1} alt="team1" />
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                  <div className="team-content">
                    <h3 className="team-title">Khabib Normano</h3>
                    <p className="team-text">Marketer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-box style-twos">
                <div className="team-thumb">
                  <img src={team2} alt="team1" />
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                  <div className="team-content">
                    <h3 className="team-title">Ayesha Siddika</h3>
                    <p className="team-text">Company CEO</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-box">
                <div className="team-thumb">
                  <img src={team3} alt="team 3" />
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                  <div className="team-content">
                    <h3 className="team-title">Mostafa Kamal Raj</h3>
                    <p className="team-text">Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-box style-foure">
                <div className="team-thumb">
                  <img src={team4} alt="team 4" />
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                  <div className="team-content">
                    <h3 className="team-title">Razaul Karim Reza</h3>
                    <p className="team-text">Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="team-bottom-title">
                  <h3>You can also find our Consultant Service to <span>Contact</span> for the consulting </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech Team Area Style Foure*/}
      {/*==================================================*/}
      {/*==================================================*/}
      {/* Start Toptech testimonial Area Style foure*/}
      {/*==================================================*/}
      <div className="testimonial-area style-foure">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title style-foure text-center">
                <h5 className="section-sub-title">TESTIMONIALS</h5>
                <h1 className="section-main-title">What Our Loving Clients Saying</h1>
              </div>
            </div>
            <div className="testi_list owl-carousel">
              <div className="col-lg-12 col-md-12">
                <div className="testi-single-box">
                  <div className="testi-author-box d-flex align-items-center">
                    <div className="testi-thumb">
                      <img src={testi1} alt="testi1" />
                    </div>
                    <div className="testi-top-content">
                      <h3 className="testi-title">Diamond Hitler</h3>
                      <span className="testi-text">Founder &amp; CEO</span>
                    </div>
                  </div>
                  <div className="testi-rating d-flex align-items-center">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                    <h4 className="testi-date">Nov 29,2023</h4>
                  </div>
                  <p className="testi-dasc">"seargin is awe-inspiring. Their relentless of
                    justice and attention to detail gave me peace
                    of mind. I highly recommend their legal
                    services; they are true advocates for clients."</p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="testi-single-box">
                  <div className="testi-author-box d-flex align-items-center">
                    <div className="testi-thumb">
                      <img src={testi2} alt="testi 2" />
                    </div>
                    <div className="testi-top-content">
                      <h3 className="testi-title">Tomasz Jaroszz</h3>
                      <span className="testi-text">Business Advisor</span>
                    </div>
                  </div>
                  <div className="testi-rating d-flex align-items-center">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                    <h4 className="testi-date">Nov 29,2023</h4>
                  </div>
                  <p className="testi-dasc">"seargin is awe-inspiring. Their relentless of
                    justice and attention to detail gave me peace
                    of mind. I highly recommend their legal
                    services; they are true advocates for clients."</p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="testi-single-box">
                  <div className="testi-author-box d-flex align-items-center">
                    <div className="testi-thumb">
                      <img src={testi3} alt="testi 3" />
                    </div>
                    <div className="testi-top-content">
                      <h3 className="testi-title">Michael Davis</h3>
                      <span className="testi-text">UI Designer</span>
                    </div>
                  </div>
                  <div className="testi-rating d-flex align-items-center">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                    <h4 className="testi-date">Nov 29,2023</h4>
                  </div>
                  <p className="testi-dasc">"seargin is awe-inspiring. Their relentless of
                    justice and attention to detail gave me peace
                    of mind. I highly recommend their legal
                    services; they are true advocates for clients."</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*==================================================*/}
      {/* End Toptech testimonial Area Style foure*/}
      {/*==================================================*/}
      <section className="awards-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-title style-foure text-left">
                <h5 className="section-sub-title">LATEST PROJECTS</h5>
                <h1 className="section-main-title">Words Meant for the Pages</h1>
                <h1 className="section-main-title">Not the Screens"</h1>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="section-title style-foure text-left">
                <p className="description">Professionaly optimize interdependent intelectual services
                  visuali design infoediaries main issue state icreative planing main
                  best practices.</p>
              </div>
            </div>
          </div>
          <div className="awards-one__inner">
            <ul>
              <li className="awards-one__single">
                <div className="content-box">
                  <div className="text-box">
                    <h2><span>01</span><a href="#">Visual Design</a><i className="bi bi-arrow-right" /></h2>
                  </div>
                </div>
                <div className="awards-one__img" style={{ opacity: 0, transform: 'translate(-50%, -50%) rotate(-5deg)', left: 842 }}><img className="awards-one__img-hover" src="assets/images/home-4/projects-img.png" alt="#" style={{ transform: 'scale(0.20, 0.20)' }} /></div>
              </li>
              <li className="awards-one__single">
                <div className="content-box">
                  <div className="text-box">
                    <h2><span>02</span><a href="#">Business Consultation</a><i className="bi bi-arrow-right" /></h2>
                  </div>
                </div>
                <div className="awards-one__img" style={{ opacity: 0, transform: 'translate(-50%, -50%) rotate(-5deg)', left: 438 }}><img className="awards-one__img-hover" src="assets/images/home-4/projects-img2.png" alt="#" style={{ transform: 'scale(0.8, 0.8)' }} /></div>
              </li>
              <li className="awards-one__single">
                <div className="content-box">
                  <div className="text-box">
                    <h2><span>03</span><a href="#">Business Agency</a><i className="bi bi-arrow-right" /></h2>
                  </div>
                </div>
                <div className="awards-one__img" style={{ opacity: 0, transform: 'translate(-50%, -50%) rotate(-5deg)', left: 1123 }}><img className="awards-one__img-hover" src="assets/images/home-4/projects-img3.png" alt="#" style={{ transform: 'scale(0.8, 0.8)' }} /></div>
              </li>
              <li className="awards-one__single">
                <div className="content-box">
                  <div className="text-box">
                    <h2><span>04</span><a href="#">Business Factory</a><i className="bi bi-arrow-right" /></h2>
                  </div>
                </div>
                <div className="awards-one__img" style={{ opacity: 0, transform: 'translate(-50%, -50%) rotate(-5deg)', left: 486 }}><img className="awards-one__img-hover" src="assets/images/home-4/projects-img4.png" alt="#" style={{ transform: 'scale(0.8, 0.8)' }} /></div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Uiux
