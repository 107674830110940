import React from "react";

const Faqs = () => {
  return (
    <>
   <div>
  {/*==================================================*/}
  {/* End Toptech Breadcumb Area */}
  {/*==================================================*/}
  {/*==================================================*/}
  {/* Start Toptech Faqs Area Inner Style*/}
  {/*==================================================*/}
  <div className="faqs-area inner-style">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="section-title left inner-style">
            <h4>Our Help &amp; Faq Us </h4>
            <h1>Navigating FAQs with Speed</h1>
            <h1>and Precision"</h1>
            <p>Business tailored it design, management &amp; support services needs to be
              business agency elit, sed do eiusmod tempor proved.</p>
          </div>
          <div className="faqs-thumb">
            <img src="assets/images/inner-images/faqs-1.jpg" alt />
          </div>        		
          <div className="faqs-thumb">
            <img src="assets/images/inner-images/faqs-2.jpg" alt />
          </div>
          <div className="faqs-icon-box">
            <div className="faqs-icon-thumb">
              <img src="assets/images/inner-images/faqs-icon-s.png" alt />
            </div>
            <div className="faqs-icon-content">
              <p>Business tailored it design, management &amp; support services busines agency elit sed do eiusmod tempor proved 
                business task state of the art infrastructures for you.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="faqs-container">
            <div className="faq-singular">
              <h2 className="faq-question"><img src="assets/images/inner-images/faqs-icon1.png" alt />Project Horizon Redevelopment</h2>
              <div className="faq-answer">
                <div className="desc">
                  Credibly conceptualize multifunctional human capital after that awesome revenue superior scenarios. Collaboratively myocardinate flexible social marketing working technologies and robust services available
                </div>
              </div>
            </div>					
            <div className="faq-singular">
              <h2 className="faq-question"><img src="assets/images/inner-images/faqs-icon1.png" alt /> What is your professional passion?</h2>
              <div className="faq-answer">
                <div className="desc">
                  Credibly conceptualize multifunctional human capital after that awesome revenue superior scenarios. Collaboratively myocardinate flexible social marketing working technologies and robust services available
                </div>
              </div>
            </div>					    
            <div className="faq-singular">
              <h2 className="faq-question"><img src="assets/images/inner-images/faqs-icon1.png" alt />Service Horizon Business low</h2>
              <div className="faq-answer">
                <div className="desc">
                  Credibly conceptualize multifunctional human capital after that awesome revenue superior scenarios. Collaboratively myocardinate flexible social marketing working technologies and robust services available
                </div>
              </div>
            </div>					    
            <div className="faq-singular">
              <h2 className="faq-question"> <img src="assets/images/inner-images/faqs-icon1.png" alt />Business Solution Redevelopment</h2>
              <div className="faq-answer">
                <div className="desc">
                  Credibly conceptualize multifunctional human capital after that awesome revenue superior scenarios. Collaboratively myocardinate flexible social marketing working technologies and robust services available
                </div>
              </div>
            </div>					   
            <div className="faq-singular">
              <h2 className="faq-question"><img src="assets/images/inner-images/faqs-icon1.png" alt />Do you have any custom services?</h2>
              <div className="faq-answer">
                <div className="desc">
                  Credibly conceptualize multifunctional human capital after that awesome revenue superior scenarios. Collaboratively myocardinate flexible social marketing working technologies and robust services available
                </div>
              </div>
            </div>										 						
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*==================================================*/}
  {/* End Toptech Faqs Area Inner Style */}
  {/*==================================================*/}
  {/*==================================================*/}
  {/* Start Toptech Subscribe Area Style Three */}
  {/*==================================================*/}
  <div className="subscribe-area style-three">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="section-title left inner-style">
            <h1>Get Latest Update from Toptech</h1>
            <h1>Stay Connect with Us</h1>
          </div>
        </div>
        <div className="col-lg-6">
          <form action="#">
            <div className="single-subscribe-box">
              <input type="text" name="Email" placeholder="Enter your Email" required />
              <button type="submit">Subscribe</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  {/*==================================================*/}
  {/* End Toptech Subscribe Area Style Three */}
  {/*==================================================*/}
  {/*==================================================*/}
  {/* Start Toptech Process Area Style Three*/}
  {/*==================================================*/}
  <div className="process-area style-three inner">
    <div className="container">
      <div className="row add-bg">
        <div className="col-lg-4 col-md-6">
          <div className="single-process-box">
            <div className="process-icon">
              <img src="assets/images/home-3/process-1.png" alt />
            </div>
            <div className="process-content">
              <h4>Account Register</h4>
              <p>Opportunities before extensible market Dramatically pursue us</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-4 col-md-6">
          <div className="single-process-box">
            <div className="process-icon">
              <img src="assets/images/home-3/process-2.png" alt />
            </div>
            <div className="process-content">
              <h4>Add New Card</h4>
              <p>Opportunities before extensible market Dramatically pursue us</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-4 col-md-6">
          <div className="single-process-box">
            <div className="process-icon">
              <img src="assets/images/home-3/process-3.png" alt />
            </div>
            <div className="process-content">
              <h4>Varyfied Account</h4>
              <p>Opportunities before extensible market Dramatically pursue us</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*==================================================*/}
  {/* End Toptech Process Area Style Three */}
  {/*==================================================*/}
  
</div>

    </>
  )
}
export default Faqs;