import React from 'react'
import feature_icon1 from '../assets/images/home-3/feature-icon-1.png'
import feature_icon2 from '../assets/images/home-3/feature-icon-2.png'
import feature_icon3 from '../assets/images/home-3/feature-icon-3.png'
import feature_icon4 from '../assets/images/home-3/feature-icon-4.png'
import about_thumb from '../assets/images/about-inner/about-thumb.png'
import about_award_icon from '../assets/images/about-inner/about-award-icon.png'
import about_counter_icon from '../assets/images/about-inner/about-counter-icon.png'
import about_shape from '../assets/images/about-inner/about-shape.png'
import team_shape from '../assets/images/team/team-shape.png'
import team_1 from '../assets/images/team/team-1.png'
import team_2 from '../assets/images/team/team-2.png'
import team_3 from '../assets/images/team/team-3.png'
import team_4 from '../assets/images/team/team-4.png'


const Digitalmarketing = () => {
  return (    
    <div> 
      <div className="feature-area style-three">
        <div className="container">
          <div className="row margin-top">
            <div className="col-lg-3 col-md-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                <img src={feature_icon1} alt="brand" />
                </div>
                <div className="feature-content">
                  <h4>Business Growth</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                <img src={feature_icon2} alt="brand" />
                </div>
                <div className="feature-content">
                  <h4>eCommerce</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                <img src={feature_icon3} alt="brand" />
                </div>
                <div className="feature-content">
                  <h4>Secure Database</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                <img src={feature_icon4} alt="brand" />
                </div>
                <div className="feature-content">
                  <h4>Audinece Growth</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="about-area inner-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="about-thumb">
                <img src={about_thumb} alt="brand" />                  
                </div>
                <div className="abour-award-box">
                  <div className="about-award-icon">
                  <img src={about_award_icon} alt="brand" />                     
                  </div>
                  <div className="about-award-content">
                    <h4>Award Wining</h4>
                    <p>Best Services</p>
                  </div>
                </div>
                <div className="about-conuter-box">
                  <div className="about-counter-icon">
                  <img src={about_counter_icon} alt="brand" /> 
                  </div>
                  <div className="about-counter-content odometer-wrapper" data-count={283}>
                    <h4>283</h4>
                    <span>K+</span>
                    <p>Complete Project</p>
                  </div>
                </div>
                <div className="about-shape">
                <img src={about_shape} alt="brand" />                
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="abour-right">
                <div className="section-title left inner-style">
                  <h4>IT Support for Business</h4>
                  <h1>About is Over 291+ Company</h1>
                  <h1>Business Solution.</h1>
                  <p className="section-desc">Monotonectally synergize granular markets and front markets. Collaboratively
                    visualize strategic infomediaries after multimedia based models. Synergistically
                    task state of the art infrastructures for</p>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="about-list-item">
                      <ul>
                        <li><i className="bi bi-check" />Company and Research</li>
                        <li><i className="bi bi-check" />Business and research</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="about-text">
                      <div className="about-number">
                        <h4>45%</h4>
                      </div>
                      <div className="about-text-content">
                        <h4>Solution Business</h4>
                        <p>Level is high</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="about-company">
                  <div className="about-company-icon">
                    <i className="bi bi-quote" />
                  </div>
                  <div className="about-company-desc">
                    <p>If you are accused of committing a crime, you will the very best criminal defense attorneys.</p>
                  </div>
                </div>
                <div className="toptech-button inner-style">
                  <a href="#">More About<i className="bi bi-arrow-right-short" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <div className="team-area style-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-title left style-two">
                <h4>Dedicated Team</h4>
                <h1>Meet Our Dedicated Member</h1>
                <h1>For Any Enquery</h1>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="toptech-button style-one">
                <a href="#">All Member<i className="bi bi-arrow-right-circle-fill" /></a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                 <img src={team_1} alt="brand" />
                </div>
                <div className="team-content">
                  <h4>John D. Alexon</h4>
                  <p>UI Designer</p>
                  <div className="team-shape">
                   <img src={team_shape} alt="brand" />
                  </div>
                </div>
                <div className="team-socila-icon-box">
                  <div className="team-share">
                    <div className="team-share-icon">
                      <span><i className="bi bi-share" /></span>
                    </div>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                 <img src={team_2} alt="brand" />
                </div>
                <div className="team-content">
                  <h4>Alexina Gomez</h4>
                  <p>HR Manager</p>
                  <div className="team-shape">
                   <img src={team_shape} alt="brand" />
                  </div>
                </div>
                <div className="team-socila-icon-box">
                  <div className="team-share">
                    <div className="team-share-icon">
                      <span><i className="bi bi-share" /></span>
                    </div>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                  <img src={team_3} alt="brand" />
                </div>
                <div className="team-content">
                  <h4>Antoine Brown</h4>
                  <p>Developer</p>
                  <div className="team-shape">
                   <img src={team_shape} alt="brand" />
                  </div>
                </div>
                <div className="team-socila-icon-box">
                  <div className="team-share">
                    <div className="team-share-icon">
                      <span><i className="bi bi-share" /></span>
                    </div>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">                  
                  <img src={team_4} alt="brand" />
                </div>
                <div className="team-content">
                  <h4>Carolina Scott</h4>
                  <p>UI Designer</p>
                  <div className="team-shape">
                    <img src={team_shape} alt="brand" />
                  </div>
                </div>
                <div className="team-socila-icon-box">
                  <div className="team-share">
                    <div className="team-share-icon">
                      <span><i className="bi bi-share" /></span>
                    </div>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="counter-area style-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                <div className="counter-content">
                  <h4>10</h4>
                  <span>K+</span>
                  <p>happy customers</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                <div className="counter-content">
                  <h4>20</h4>
                  <span>K+</span>
                  <p>Works Completed</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                <div className="counter-content">
                  <h4>99</h4>
                  <span>+</span>
                  <p>Expert Members</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-box">
                <div className="counter-content">
                  <h4>100</h4>
                  <span>%</span>
                  <p>Satisfaction Rates</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="testimonial-area style-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title center style-one">
                <h4>Testimonial</h4>
                <h1>Customer’s Awesome Feedback</h1>
                <h1>About Our Services</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="testi-list-1 owl-carousel">
              <div className="col-lg-12 text-center">
                <div className="single-testimonial-box">
                  <div className="testi-desc">
                    <p>Completely extend leveraged customer service rather than performance based imperatives.
                      magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                      technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                      Authoritatively deliver highly efficient expertise</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                  </div>
                  <div className="testi-author">
                    <h4>Venkatesh Iyer</h4>
                    <p>UI Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-center">
                <div className="single-testimonial-box">
                  <div className="testi-desc">
                    <p>Completely extend leveraged customer service rather than performance based imperatives.
                      magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                      technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                      Authoritatively deliver highly efficient expertise</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                  </div>
                  <div className="testi-author">
                    <h4>John D. Alexon</h4>
                    <p>UI Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-center">
                <div className="single-testimonial-box">
                  <div className="testi-desc">
                    <p>Completely extend leveraged customer service rather than performance based imperatives.
                      magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                      technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                      Authoritatively deliver highly efficient expertise</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                  </div>
                  <div className="testi-author">
                    <h4>Alex Brock </h4>
                    <p>UI Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 text-center">
                <div className="single-testimonial-box">
                  <div className="testi-desc">
                    <p>Completely extend leveraged customer service rather than performance based imperatives.
                      magnetic relationships rather than leveraged e-markets. Rapidiously transform timely niches
                      technology. Enthusiastically e-enable global e-markets for cooperative e-business.
                      Authoritatively deliver highly efficient expertise</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                  </div>
                  <div className="testi-author">
                    <h4>Abhishek Sharma</h4>
                    <p>UI Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Digitalmarketing
