import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/images/home-1/logo.png';

const Header = () => {
    return (
        <>
            <div className="header-area style-two" id="sticky-header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="header-logo">
                                <Link to="/"><img src={logo} alt="logo" /></ Link>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu">
                                <ul>
                                    <li className="menu-item-has-children"><Link to="/">Home</ Link></li>
                                    <li className="menu-item-has-children"><Link to="#">About<i className="fas fa-chevron-down" /></ Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/about/our-company">Our Company</ Link></li>
                                            <li><Link to="/about/company-history">Company History</ Link></li>
                                            <li><Link to="/about/our-team">Our Team</ Link></li>
                                            <li><Link to="/about/our-partners">Our Partners</ Link></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="#">Services<i className="fas fa-chevron-down" /></ Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/services/digital-marketing">Digital Marketing</ Link></li>
                                            <li><Link to="services/mobile-application">Mobile Application</ Link></li>
                                            <li><Link to="/services/web-application">Web Application</ Link></li>                                         
                                            <li><Link to="/services/ui-ux">UI-UX</ Link></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="#">Projects<i className="fas fa-chevron-down" /></ Link>
                                        <ul className="sub-menu">
                                            <li><Link to="https://www.fairsearches.com/" title="Fair Searches">Fair Searches</ Link></li>
                                            <li><Link to="https://www.studyextra.in/" title="Study Extra">Study Extra </ Link></li>
                                            <li><Link to="https://www.fairsjob.com/" title="fairs job"> fairs job</ Link></li>                                            
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="/blog">Blog</ Link></li>
                                    <li className="menu-item-has-children"><Link to="#">Insights<i className="fas fa-chevron-down" /></ Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/faqs">FAQs</ Link></li>
                                            <li><Link to="https://www.fairsjob.com/" target="_blank">Careers</ Link></li>
                                            <li><Link to="/privacy-policy">Privacy Policy</ Link></li>                                      
                                        </ul>
                                    </li>
                                    <li><Link to="/contact-us">Contact</ Link></li>
                                </ul>                               
                                <div className="header-button">
                                    <Link to="/contact-us">Get A Quote</ Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Header;