import React from "react";
import { Link } from "react-router-dom";

const Mainmenu = () => {
    return (
        <div>
            <div className="mobile-menu-area sticky d-sm-block d-md-block d-lg-none ">
                <div className="mobile-menu">
                    <nav className="header-menu">
                        <ul className="nav_scroll">
                            <li className="menu-item-has-children"><Link to="/">Home</ Link></li>
                            <li className="menu-item-has-children"><a href="#">About</a>
                                <ul className="sub-menu">
                                    <li><Link to="/about/our-company">Our Company</ Link></li>
                                    <li><Link to="/about/company-history">Company History</ Link></li>
                                    <li><Link to="/about/our-team">Our Team</ Link></li>
                                    <li><Link to="/about/our-partners">Our Partners</ Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children"><a href="#">Service</a>
                                <ul className="sub-menu">
                                    <li><Link to="/services/digital-marketing">Digital Marketing</ Link></li>
                                    <li><Link to="/services/mobile-application">Mobile Application</ Link></li>
                                    <li><Link to="/services/web-application">Web Application</ Link></li>
                                    <li><Link to="/services/ui-ux">UI-UX</ Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children"><a href="#">Projects</a>
                                <ul className="sub-menu">
                                    <li><Link to="https://www.fairsearches.com/" title="Fair Searches">Fair Searches</ Link></li>
                                    <li><Link to="https://www.studyextra.in/" title="Study Extra">Study Extra </ Link></li>
                                    <li><Link to="https://fairsjob.com/" title="Fairs Job"> fairs job </ Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children"><Link to="/blog">Blog</ Link></li>                          
                            <li className="menu-item-has-children"><a href="#">Insights</a>
                                <ul className="sub-menu">
                                <li><Link to="/faqs">FAQs</ Link></li>
                                            <li><Link to="https://fairsjob.com/">Careers</ Link></li>
                                            <li><Link to="/privacy-policy">Privacy Policy</ Link></li>
                                </ul>
                            </li>
                            <li><Link to="/contact-us">Contact</ Link></li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>
    )
}

export default Mainmenu