import React from 'react';
import process1 from '../assets/images/home-3/process-1.png'
import process2 from '../assets/images/home-3/process-2.png'
import process3 from '../assets/images/home-3/process-3.png'

import team1 from '../assets/images/home-1/team1.jpg'
import team2 from '../assets/images/home-1/team2.jpg'
import team3 from '../assets/images/home-1/team3.jpg'
import team4 from '../assets/images/home-1/team4.jpg'

const Ourteam = () => {
  return (
    <div>      
      <div className="team-area style-one inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title left style-two">
                <h4>Dedicated Team</h4>
                <h1>Meet Our Dedicated Member</h1>
                <h1>For Any Enquery</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="toptech-button style-one">
                <a href="#">All Member<i className="bi bi-arrow-right-circle-fill" /></a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                  <img src={team1} alt="team1" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h4>Arianna Bibi</h4>
                    <p>Web Developer</p>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                  <img src={team2} alt="team2" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h4>Nazmul Hossen</h4>
                    <p>UI/UX Designar</p>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                  <img src={team3} alt="team3" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h4>Roman Soltana </h4>
                    <p>Manager</p>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-box">
                <div className="team-thumb">
                  <img src={team4} alt="team 4" />
                </div>
                <div className="team-content">
                  <div className="team-bio">
                    <h4>Ghaura Mogibor </h4>
                    <p>UI/UX Developer</p>
                  </div>
                  <div className="team-social-icon">
                    <ul>
                      <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-youtube" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="subscribe-area style-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title left inner-style">
                <h1>Get Latest Update from Toptech</h1>
                <h1>Stay Connect with Us</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <form action="#">
                <div className="single-subscribe-box">
                  <input type="text" name="Email" placeholder="Enter your Email" required />
                  <button type="submit">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
   
      <div className="process-area style-three inner">
        <div className="container">
          <div className="row add-bg">
            <div className="col-lg-4 col-md-6">
              <div className="single-process-box">
                <div className="process-icon">
                  <img src={process1} alt="process 1" />
                </div>
                <div className="process-content">
                  <h4>Account Register</h4>
                  <p>Opportunities before extensible market Dramatically pursue us</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-process-box">
                <div className="process-icon">
                <img src={process2} alt="process 2" />
                </div>
                <div className="process-content">
                  <h4>Add New Card</h4>
                  <p>Opportunities before extensible market Dramatically pursue us</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-process-box">
                <div className="process-icon">
                  <img src={process3} alt="process 3" />
                </div>
                <div className="process-content">
                  <h4>Varyfied Account</h4>
                  <p>Opportunities before extensible market Dramatically pursue us</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>

  );
}

export default Ourteam;
