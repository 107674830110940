import React from "react";
import { Outlet } from "react-router-dom";

import Footer from '../components/Footer'
import Mainmenu from "../components/Mainmenu"
import Headerinner from "../components/Headerinner";
import Header from "../components/Header";
import Bannerinner from "../components/Bannerinner";
import Brandarea from "../components/Brandarea";

const Layout = () => {   
    return (
        <div>          
            <Header />
            <Mainmenu /><br /><br /><br /><br />
            <Bannerinner />
            <Outlet />
            <Brandarea />
            <Footer />
        </div>
    );
}

export default Layout