import React from 'react'
import feature_icon_1 from '../assets/images/home-3/feature-icon-1.png'
import feature_icon_2 from '../assets/images/home-3/feature-icon-2.png'
import feature_icon_3 from '../assets/images/home-3/feature-icon-3.png'
import feature_icon_4 from '../assets/images/home-3/feature-icon-4.png'
import about_thumb from '../assets/images/home-3/about-thumb.png'
import about_award_icon from '../assets/images/about-inner/about-award-icon.png'
import about_shape from '../assets/images/home-3/about-shape.png'
import about_call_icon from '../assets/images/home-3/about-call-icon.png'

import blog2 from '../assets/images/home-1/blog2.png'
import blog3 from '../assets/images/home-1/blog3.png'
import process1 from '../assets/images/home-3/process-1.png'
import process2 from '../assets/images/home-3/process-2.png'
import process3 from '../assets/images/home-3/process-3.png'

import testi_author_1 from '../assets/images/home-3/testi-author-1.png'
import testi_author_2 from '../assets/images/home-3/testi-author-2.png'
import testi_author_3 from '../assets/images/home-3/testi-author-3.png'
import teasti_quote from '../assets/images/home-3/teasti-quote.png'

import portfolio1 from '../assets/images/home-3/portfolio-1.png'
import portfolio2 from '../assets/images/home-3/portfolio-2.png'
import portfolio3 from '../assets/images/home-3/portfolio-3.png'


import pricing_icon from '../assets/images/home-3/pricing-icon.png'
import contact_shape from '../assets/images/home-3/contact-shape.png'
import contact_thumb from '../assets/images/home-3/contact-thumb.png'
import contact_icon from '../assets/images/home-3/contact-icon.png'


import service_icon_1 from '../assets/images/home-3/service-icon-1.png'
import service_icon_2 from '../assets/images/home-3/service-icon-2.png'
import service_icon_3 from '../assets/images/home-3/service-icon-3.png'
import service_icon_4 from '../assets/images/home-3/service-icon-4.png'
import service_icon_5 from '../assets/images/home-3/service-icon-5.png'
import service_icon_6 from '../assets/images/home-3/service-icon-6.png'
import about_shape_2 from '../assets/images/home-3/about-shape-2.png'

const Webapplication = () => {
  return (
    <div>   
      {/*==================================================*/}
      {/* Start Toptech Feature Area Style Three*/}
      {/*==================================================*/}
      <div className="feature-area style-three">
        <div className="container">
          <div className="row margin-top">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                  <img src={feature_icon_1} alt="feature_icon_1" />
                </div>
                <div className="feature-content">
                  <h4>Business Growth</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                  <img src={feature_icon_2} alt="feature_icon_2" />
                </div>
                <div className="feature-content">
                  <h4>eCommerce</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                  <img src={feature_icon_3} alt="feature_icon_3" />
                </div>
                <div className="feature-content">
                  <h4>Secure Database</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-feature-box">
                <div className="feature-icon">
                  <img src={feature_icon_4} alt="feature_icon_4" />
                </div>
                <div className="feature-content">
                  <h4>Audinece Growth</h4>
                  <p>Completely fashion reliable more products grow business</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="about-area style-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="about-thumb">
                  <img src={about_thumb} alt="about_thumb" />
                </div>
                <div className="about-video-box">
                  <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://www.youtube.com/watch?v=e6R6VsgD8yQ&amp;t=179s"><i className="bi bi-play-fill" /></a>
                </div>
                <div className="abour-award-box">
                  <div className="about-award-icon">
                    <img src={about_award_icon} alt="about_thumb" />
                  </div>
                  <div className="about-award-content">
                    <h4>Award Wining</h4>
                    <p>Best Services</p>
                  </div>
                </div>
                <div className="about-shape">
                  <img src={about_shape} alt="about_thumb" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="abour-right">
                <div className="section-title left style-two">
                  <h4>IT Support for Business</h4>
                  <h1>Ensuring Your Success Trusted </h1>
                  <h1>IT Services Source</h1>
                  <p className="section-desc">Monotonectally synergize granular markets and front markets. Collaboratively
                    visualize strategic infomediaries after multimedia based models. Synergistically
                    task state of the art infrastructures for</p>
                </div>
                <div className="about-list-item">
                  <ul>
                    <li><i className="bi bi-check-lg" />Holisticly matrix highly modelling</li>
                    <li><i className="bi bi-check-lg" />Growing audience for Business</li>
                    <li><i className="bi bi-check-lg" />24/7 Technical Supports</li>
                  </ul>
                </div>
                <div className="about-phone">
                  <div className="about-phone-icon">
                    <img src={about_call_icon} alt="about_call_icon" />
                  </div>
                  <div className="about-phone-content">
                    <h4>+8750 (299) 299</h4>
                  </div>
                </div>
                <div className="toptech-button inner-style">
                  <a href="about-1.html">More About<i className="bi bi-arrow-right-short" /></a>
                </div>
                <div className="about-shape-two">
                  <img src={about_shape_2} alt="about-shape-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="service-area style-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title center style-two">
                <h4>What we Provides? </h4>
                <h1>Provide Interective IT Solution</h1>
                <h1>Business Services</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-box">
                <div className="service-icon">
                <img src={service_icon_1} alt="service-icon-1" />
                </div>
                <div className="service-content">
                  <h4>3D Animation Design</h4>
                  <p>Monotonectally synergize grants to business visualize strategic infomediaries main issue
                    state infrastructure done</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-box">
                <div className="service-icon">
                <img src={service_icon_2} alt="service-icon-2" />
                </div>
                <div className="service-content">
                  <h4>Web Development </h4>
                  <p>Monotonectally synergize grants to business visualize strategic infomediaries main issue
                    state infrastructure done</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-box">
                <div className="service-icon">
                <img src={service_icon_3} alt="service-icon-3" />
                </div>
                <div className="service-content">
                  <h4>Digital Marketing </h4>
                  <p>Monotonectally synergize grants to business visualize strategic infomediaries main issue
                    state infrastructure done</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-box">
                <div className="service-icon">
                <img src={service_icon_4} alt="service-icon-4" />
                </div>
                <div className="service-content">
                  <h4>VFX And Video Editing </h4>
                  <p>Monotonectally synergize grants to business visualize strategic infomediaries main issue
                    state infrastructure done</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-box">
                <div className="service-icon">
                <img src={service_icon_5} alt="service-icon-5" />
                </div>
                <div className="service-content">
                  <h4>Video Edingig </h4>
                  <p>Monotonectally synergize grants to business visualize strategic infomediaries main issue
                    state infrastructure done</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-box">
                <div className="service-icon">
                  <img src={service_icon_6} alt="service-icon-6" />
                </div>
                <div className="service-content">
                  <h4>Google Top Ranking </h4>
                  <p>Monotonectally synergize grants to business visualize strategic infomediaries main issue
                    state infrastructure done</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="contact-area-info style-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-icon">
                <img src={contact_icon} alt="contact-icon" />
              </div>
              <div className="contact-content">
                <h6>Call Us Anytime</h6>
                <h1>+8750 (299) 299</h1>
                <p>Professionally optimize interdependent intellectual interoperable
                  best practices. Progressively fabricate</p>
              </div>
              <div className="toptech-button inner-style">
                <a href="contact.html">Let’s Talk<i className="bi bi-arrow-right-short" /></a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-right">
                <div className="contact-thumb">
                  <img src={contact_thumb} alt="contact-thumb" />
                </div>
                <div className="contact-shape">
                  <img src={contact_shape} alt="contact-shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="counter-area style-three">
        <div className="container">
          <div className="row add-bg">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-counter-box">
                <div className="counter-content">
                  <h4>960</h4>
                  <span>+</span>
                  <p>Active Coustomer </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-counter-box">
                <div className="counter-content">
                  <h4>90</h4>
                  <span>+</span>
                  <p>Expert Members </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-counter-box">
                <div className="counter-content">
                  <h4>852</h4>
                  <span>+</span>
                  <p>Satisfied Customers </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-counter-box last-child">
                <div className="counter-content">
                  <h4>100</h4>
                  <span>%</span>
                  <p>Satisfaction Rate </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="choose-us-area style-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-title left inner-style">
                <h4>Why Choose Us?</h4>
                <h1>Building your Path to Success</h1>
                <h1>with a Reliable IT Service</h1>
                <p className="section-desc">Monotonectally synergize granular markets front markets. Collaboratively
                  visualize strategic infomediaries after multimedia based.Synergistically to
                  task state of the art infrastructures</p>
              </div>
              <div className="wrapper">
                <div className="skill">
                  <p>IT Solutions</p>
                  <div className="skill-bar skill1 wow slideInLeft animated">
                    <span className="skill-count1">95%</span>
                  </div>
                </div>
                <div className="skill">
                  <p>Development</p>
                  <div className="skill-bar skill2 wow slideInLeft animated">
                    <span className="skill-count2">85%</span>
                  </div>
                </div>
                <div className="toptech-button inner-style">
                  <a href="about-1.html">More About<i className="bi bi-arrow-right-short" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-pricing-box">
                <div className="pricing-icon">
                  <img src={pricing_icon} alt="pricing-icon" />
                </div>
                <div className="pricing-content">
                  <h4>Busines Plan</h4>
                  <p>The markets and front market</p>
                  <h1>$30 <span>/ Month</span> </h1>
                </div>
                <div className="pricing-list">
                  <ul>
                    <li>10+ user Account</li>
                    <li>Moneyback Gaurentee</li>
                    <li>Unlimited Database</li>
                    <li>24/7 Supports</li>
                  </ul>
                </div>
                <div className="pricing-button">
                  <a href="#">Purchaces</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-pricing-box two">
                <div className="pricing-icon">
                  <img src={pricing_icon} alt="pricing-icon" />
                </div>
                <div className="pricing-content">
                  <h4>Popular Plan</h4>
                  <p>The markets and front market</p>
                  <h1>$10 <span>/ Month</span> </h1>
                </div>
                <div className="pricing-list">
                  <ul>
                    <li>10+ user Account</li>
                    <li>Moneyback Gaurentee</li>
                    <li>Unlimited Database</li>
                    <li>24/7 Supports</li>
                  </ul>
                </div>
                <div className="pricing-button">
                  <a href="#">Purchaces</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="portfolio-area style-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title center style-two">
                <h4>Latest Project </h4>
                <h1>Our Recent Launched Projects</h1>
                <h1>Available into Market</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="portfolio-list-3 owl-carousel">
              <div className="col-lg-12">
                <div className="single-portfolio-box">
                  <div className="portfolio-thumb">
                    <img src={portfolio1} alt="portfolio-1" />
                  </div>
                  <div className="portfolio-content">
                    <h4>IT Solution</h4>
                    <a href="project-details.html">Website Development</a>
                    <a className="button" href="project.html"><i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-portfolio-box">
                  <div className="portfolio-thumb">
                  <img src={portfolio2} alt="portfolio-2" />
                  </div>
                  <div className="portfolio-content">
                    <h4>Designing</h4>
                    <a href="project-details.html">Mobile UI/UX Design</a>
                    <a className="button" href="project.html"><i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-portfolio-box">
                  <div className="portfolio-thumb">
                    <img src={portfolio3} alt="portfolio-3" />
                  </div>
                  <div className="portfolio-content">
                    <h4>Technology</h4>
                    <a href="project-details.html">Cyber Security &amp; Protect</a>
                    <a className="button" href="project.html"><i className="bi bi-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="teastimonial-area style-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title center style-two">
                <h4>Testimonial </h4>
                <h1>What say our Valuable Clients</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-testi-box">
                <div className="testi-content">
                  <div className="testi-quote-icon">
                    <img src={teasti_quote} alt="teasti-quote" />
                  </div>
                  <div className="testi-desc">
                    <p>Monotonectally synergize granular that visualize strategic infomediaries business task state of the art into infrastructures markets digital products</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                    </ul>
                  </div>
                </div>
                <div className="teasti-author">
                  <div className="testi-author-thumb">
                    <img src={testi_author_1} alt="testi-author-1" />
                  </div>
                  <div className="testi-author-content">
                    <h4>John D. Alexon</h4>
                    <p>UI Designer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-testi-box">
                <div className="testi-content">
                  <div className="testi-quote-icon">
                    <img src={teasti_quote} alt="teasti-quote" />
                  </div>
                  <div className="testi-desc">
                    <p>Monotonectally synergize granular that visualize strategic infomediaries business task state of the art into infrastructures markets digital products</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                    </ul>
                  </div>
                </div>
                <div className="teasti-author">
                  <div className="testi-author-thumb">
                    <img src={testi_author_2} alt="testi-author-2" />
                  </div>
                  <div className="testi-author-content">
                    <h4>Hasin A. Hydar</h4>
                    <p>Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-testi-box">
                <div className="testi-content">
                  <div className="testi-quote-icon">
                    <img src={teasti_quote} alt="teasti-quote" />
                  </div>
                  <div className="testi-desc">
                    <p>Monotonectally synergize granular that visualize strategic infomediaries business task state of the art into infrastructures markets digital products</p>
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                      <li><i className="fa-solid fa-star" /></li>
                    </ul>
                  </div>
                </div>
                <div className="teasti_author">
                  <div className="testi-author-thumb">
                    <img src={testi_author_3} alt="testi-author-3" />
                  </div>
                  <div className="testi-author-content">
                    <h4>Alexina Gomez</h4>
                    <p>UI Designer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="subscribe-area style-three">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title left inner-style">
                <h1>Get Latest Update from Toptech</h1>
                <h1>Stay Connect with Us</h1>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <form action="#">
                <div className="single-subscribe-box">
                  <input type="text" name="Email" placeholder="Enter your Email" required />
                  <button type="submit">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
     
      <div className="process-area style-three">
        <div className="container">
          <div className="row add-bg">
            <div className="col-lg-4 col-md-6">
              <div className="single-process-box">
                <div className="process-icon">
                 <img src={process1} alt="process 1" />
                </div>
                <div className="process-content">
                  <h4>Account Register</h4>
                  <p>Opportunities before extensible market Dramatically pursue us</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-process-box">
                <div className="process-icon">
                <img src={process2} alt="process 2" />
                </div>
                <div className="process-content">
                  <h4>Add New Card</h4>
                  <p>Opportunities before extensible market Dramatically pursue us</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-process-box">
                <div className="process-icon">
                  <img src={process3} alt="process 3" />
                </div>
                <div className="process-content">
                  <h4>Varyfied Account</h4>
                  <p>Opportunities before extensible market Dramatically pursue us</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="blog-area style-one style-three">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title center style-two">
                <h4>Latest Blog</h4>
                <h1>Latest Inshights From Our Blog</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-box">
                <div className="blog-thumb">
                 <img src={blog2} alt="blog 2" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <span><i className="bi bi-calendar-check-fill" /> December 14, 2024</span>
                  </div>
                  <a href="blog-details.html">Most Popular Chrome Extensionfor Business Promot</a>
                </div>
                <div className="blog-buttom">
                  <div className="blog-author">
                    <span><i className="bi bi-person-circle" />By Admin </span>
                  </div>
                  <div className="blog-button">
                    <a href="blog-grid.html">Read More<i className="bi bi-arrow-right-short" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-box">
                <div className="blog-thumb">
                 <img src={blog2} alt="blog 2" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <span><i className="bi bi-calendar-check-fill" /> December 14, 2024</span>
                  </div>
                  <a href="blog-details.html">How to Secure your facebook Business Account </a>
                </div>
                <div className="blog-buttom">
                  <div className="blog-author">
                    <span><i className="bi bi-person-circle" />By Admin </span>
                  </div>
                  <div className="blog-button">
                    <a href="blog-grid.html">Read More<i className="bi bi-arrow-right-short" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-box">
                <div className="blog-thumb">
                  <img src={blog3} alt="blog3" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <span><i className="bi bi-calendar-check-fill" /> December 14, 2024</span>
                  </div>
                  <a href="blog-details.html">Better Context Menus With Safe Triangles</a>
                </div>
                <div className="blog-buttom">
                  <div className="blog-author">
                    <span><i className="bi bi-person-circle" />By Admin </span>
                  </div>
                  <div className="blog-button">
                    <a href="blog-grid.html">Read More<i className="bi bi-arrow-right-short" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="contact-area style-three">
        <div className="container">
          <div className="row add-white-bg align-items-center">
            <div className="col-lg-8 col-md-12">
              <div className="single-contact-box">
                <div className="contact-contetn">
                  <h4>Write to Us Anytime</h4>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-input-box">
                        <input type="text" name="name" placeholder="Your Name" required />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-box">
                        <input type="text" name="Email" placeholder="Enter E-Mail" required />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-box">
                        <input type="text" name="Phone" placeholder="Phone Number" required />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-box">
                        <select name="place" id="place">
                          <option value="saab">Subject</option>
                          <option value="opel">Web Development </option>
                          <option value="audi">Design </option>
                          <option value="audi">Marketing</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-box">
                        <textarea name="massage" id="massage" placeholder="Write Massage" required defaultValue={""} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="massage-sent-button">
                        <button type="submit">Send Massage </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="single-contact-info-box">
                <div className="info-content">
                  <h4>Don’t Forget to Contact Us</h4>
                </div>
                <div className="contact-info-box">
                  <div className="contact-info-icon">
                    <i className="bi bi-telephone-fill" />
                  </div>
                  <div className="contact-info-content">
                    <p>Call Us</p>
                    <h4>8750 (299) 299</h4>
                  </div>
                </div>
                <div className="contact-info-box">
                  <div className="contact-info-icon">
                    <i className="bi bi-envelope-open-fill" />
                  </div>
                  <div className="contact-info-content">
                    <p>Send E-Mail</p>
                    <h4>info@dharamvirinfotech.com</h4>
                  </div>
                </div>
                <div className="contact-info-box">
                  <div className="contact-info-icon">
                    <i className="bi bi-alarm" />
                  </div>
                  <div className="contact-info-content">
                    <p>Office Hours</p>
                    <h4>9.30 AM to 6.30 PM</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>

  )
}

export default Webapplication
