import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Layout from './includes/Layout';
import Layouthome from './includes/Layouthome'; 
import Faqs from './pages/Faqs';
import Privacypolicy from './pages/Privacypolicy';
import Digitalmarketing from './pages/Digitalmarketing';
import Ourcompany from './pages/Ourcompany';
import Companyhistory from './pages/Companyhistory';
import Ourteam from './pages/Ourteam';
import Ourpartners from './pages/Ourpartners';
import Uiux from './pages/Uiux';
import Webapplication from './pages/Webapplication';
import Mobileapplication from './pages/Mobileapplication';



function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<Layouthome />} >
            <Route path='/' element={<Home />} />
          </Route>
          <Route element={<Layout />} >
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path='/services/digital-marketing' element={<Digitalmarketing /> } />
            <Route path='/services/mobile-application' element={<Mobileapplication /> } />
            <Route path='about/our-company' element={<Ourcompany />} />
            <Route path='about/company-history' element={<Companyhistory />} />
            <Route path='about/our-team' element={<Ourteam />} />
            <Route path='about/our-partners' element={<Ourpartners />} />
            <Route path='services/ui-ux' element={<Uiux />} />
            <Route path='services/web-application' element={<Webapplication />} />
            <Route path="/faqs" element={<Faqs />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
