import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import herothumb from '../assets/images/home-2/hero-thumb.png'
import heroshape from '../assets/images/home-2/hero-shape.png'
const Bannerinner = () => {
    const [pageURL, setPageURL] = useState(0);
    const location = useLocation();

    var parts = location.pathname.split('/');
    var lastSegment = parts.pop() || parts.pop();
    useEffect(()=>{        
        setPageURL(lastSegment);
    },[])
    return (
        <div>
            <div className="breadcumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcumb-content">
                                <h4>{pageURL}</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>&gt;</li>
                                    <li>{pageURL}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Bannerinner