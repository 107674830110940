import React from 'react'
import team_details from '../assets/images/team/team-details.jpg'

const Companyhistory = () => {
  return (
      <div>        
        <div className="team-details-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="team-thumb">
                  <img src={team_details} alt="team details" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="team-details-right">
                  <div className="team-details-content">
                    <div className="team-member-title">
                      <h4>Anowar Hossin Omio</h4>
                      <p>Dream-IT Founder &amp; CEO</p>
                    </div>
                    <p className="desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor creative
                      labore et dolore magna aliqua ipsum suspendisse ultrices gravida commodo viverra accu
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                  <div className="row add-bg">
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-info-box">
                        <div className="contact-info-icon">
                          <i className="fa-solid fa-phone" />
                        </div>
                        <div className="contact-info-content">
                          <h4>Call us Any time</h4>
                          <p>+8750 (299) 299</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact-info-box">
                        <div className="contact-info-icon">
                          <i className="fa-regular fa-envelope" />
                        </div>
                        <div className="contact-info-content">
                          <h4>Send E-Mail</h4>
                          <p>info@dharamvirinfotech.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="team-details-social-icon">
                    <ul>
                      <li>Social Media</li>
                      <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-linkedin-in" /></a></li>
                      <li><a href="#"><i className="fa-brands fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                  <div className="team-details-location-box">
                    <div className="contact-info-icon">
                      <i className="bi bi-geo-alt-fill" />
                    </div>
                    <div className="contact-info-content">
                      <h4>Location</h4>
                      <p>21 King Street 5th Floor Hamilton, Ontario Canada</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="team-details-skills">
                  <h4>My Skills</h4>
                  <div className="wrapper">
                    <div className="skill">
                      <p>Design Analytics</p>
                      <div className="skill-bar skill1 wow slideInLeft animated animated" style={{ visibility: 'visible', animationName: 'slideInLeft' }}>
                        <span className="skill-count1">95%</span>
                      </div>
                    </div>
                    <div className="skill">
                      <p>Software Development</p>
                      <div className="skill-bar skill2 wow slideInLeft animated animated" style={{ visibility: 'visible', animationName: 'slideInLeft' }}>
                        <span className="skill-count2">85%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="team-details-skills">
                  <h4>Success Project </h4>
                  <div className="wrapper">
                    <div className="skill">
                      <p>UI/UX Design &amp; Development</p>
                      <div className="skill-bar skill1 wow slideInLeft animated animated" style={{ visibility: 'visible', animationName: 'slideInLeft' }}>
                        <span className="skill-count1">95%</span>
                      </div>
                    </div>
                    <div className="skill">
                      <p>Content Strategy</p>
                      <div className="skill-bar skill2 wow slideInLeft animated animated" style={{ visibility: 'visible', animationName: 'slideInLeft' }}>
                        <span className="skill-count2">85%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>       
      </div>
  )
}

export default Companyhistory
