import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Topheader from "../components/Topheader"
import Footer from '../components/Footer'
import Mainmenu from "../components/Mainmenu"
import Bannerhome from "../components/Bannerhome"

const Layouthome = () => {
    return (
        <div>
            <Topheader />
            <Header />
            <Mainmenu />
            <Bannerhome />
            <Outlet />     
            <Footer />
        </div>
    );
}

export default Layouthome