import React from "react";
import brand1 from '../assets/images/home-2/brand-1.png'
import brand2 from '../assets/images/home-2/brand-2.png'
import brand3 from '../assets/images/home-2/brand-3.png'
import brand4 from '../assets/images/home-2/brand-4.png'
import brand5 from '../assets/images/home-2/brand-5.png'

const Brandarea = () => {

    return (
        <div>
            <div className="brand-area style-two">
                <div className="container">
                    <div className="row">
                        <div className="brand-list-1 owl-carousel">
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand1} alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand2} alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand3} alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand4} alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="single-brand-box">
                                    <div className="brand-thumb">
                                        <img src={brand5} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Brandarea