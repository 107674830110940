import React from 'react'
import process_3 from '../assets/images/home-3/process-3.png'
import process_2 from '../assets/images/home-3/process-2.png'
import process_1 from '../assets/images/home-3/process-1.png'
import choose_us_icon_1 from '../assets/images/home-3/process-3.png'
import choose_us_icon_2 from '../assets/images/home-3/process-3.png'
import choose_us_icon_3 from '../assets/images/home-3/process-3.png'
import choose_us_icon_4 from '../assets/images/home-3/process-3.png'
import choose_us_thumb from '../assets/images/inner-images/choose-us-thumb.png'

function Mobileapplication() {
  return (
   <div>
  <div className="choose-us-area inner-style">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="section-title left style-two">
            <h4>Why Choose Us</h4>
            <h1>The Toptech IT Services Promise"</h1>
            <p>Monotonectally synergize granular markets and front markets collaboratively 
              task state of the art infrastructures for granular Maecenas varius sem dui buildings
              semper ante a viverra posuere maecenas fermentum.</p>
          </div>
          <div className="single-choose-us-item">
            <ul>
              <li>Achieve your goals and exceed expectations.</li>
              <li>Building a website, mobile app or software.</li>
              <li>Our platform is designed to help you.</li>
              <li>Our platform places emphasis on user experience.</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="choose-us-thumb">
            <img src={choose_us_thumb} alt="process 2" />            
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row add-marging">
        <div className="col-lg-3 col-md-6">
          <div className="single-choose-us-box">
            <div className="choose-us-icon">
            <img src={choose_us_icon_1} alt="process 2" /> 
            </div>
            <div className="choose-us-content">
              <h4>Data Security</h4>
              <p>Monotonectally synergize granular to front markets task state data security
                semper maecenas ferform.</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-3 col-md-6">
          <div className="single-choose-us-box">
            <div className="choose-us-icon">
            <img src={choose_us_icon_2} alt="process 2" />              
            </div>
            <div className="choose-us-content">
              <h4>IT Services</h4>
              <p>Monotonectally synergize granular to front markets task state data security
                semper maecenas ferform.</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-3 col-md-6">
          <div className="single-choose-us-box">
            <div className="choose-us-icon">
            <img src={choose_us_icon_3} alt="process 3" />              
            </div>
            <div className="choose-us-content">
              <h4>Data Security</h4>
              <p>Monotonectally synergize granular to front markets task state data security
                semper maecenas ferform.</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-3 col-md-6">
          <div className="single-choose-us-box">
            <div className="choose-us-icon">
            <img src={choose_us_icon_4} alt="choose us icon 4" />              
            </div>
            <div className="choose-us-content">
              <h4>People</h4>
              <p>Monotonectally synergize granular to front markets task state data security
                semper maecenas ferform.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div className="subscribe-area style-three">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="section-title left inner-style">
            <h1>Get Latest Update from Toptech</h1>
            <h1>Stay Connect with Us</h1>
          </div>
        </div>
        <div className="col-lg-6">
          <form action="#">
            <div className="single-subscribe-box">
              <input type="text" name="Email" placeholder="Enter your Email" required />
              <button type="submit">Subscribe</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div className="process-area style-three inner">
    <div className="container">
      <div className="row add-bg">
        <div className="col-lg-4 col-md-6">
          <div className="single-process-box">
            <div className="process-icon">
            <img src={process_1} alt="process 1" />               
            </div>
            <div className="process-content">
              <h4>Account Register</h4>
              <p>Opportunities before extensible market Dramatically pursue us</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-4 col-md-6">
          <div className="single-process-box">
            <div className="process-icon">
            <img src={process_2} alt="process 2" />              
            </div>
            <div className="process-content">
              <h4>Add New Card</h4>
              <p>Opportunities before extensible market Dramatically pursue us</p>
            </div>
          </div>
        </div>			
        <div className="col-lg-4 col-md-6">
          <div className="single-process-box">
            <div className="process-icon">
              <img src={process_3} alt="process 3" />
            </div>
            <div className="process-content">
              <h4>Varyfied Account</h4>
              <p>Opportunities before extensible market Dramatically pursue us</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Mobileapplication
